import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import CheatsheetsListAll from '../../components/CheatsheetsListAll'

const CheatsheetsIndexPage = ({
  data: {
    site: {
      siteMetadata: {
        title,
        host
      },
    },
  },
  location
}) => {
  const metaTitle = `Taháky | ${title}`;
  const metaUrl = `${host}${location.pathname || '/'}`;

  return (
    <Layout>
      <Helmet title={metaTitle}>
        <meta property="og:title" content={metaTitle}/>
        <meta property="og:url" content={metaUrl}/>
        <link rel="canonical" href={metaUrl} />
        <meta name="twitter:title" content={metaTitle} />
      </Helmet>
      <div className='main uk-width-auto section' id='main'>
        <div className="single_post hfeed" id="single_post">
          <article className="post hentry">
            <div className="post_content">
              <h1 className="post_title entry-title uk-article-title">Taháky</h1>
            </div>
          </article>
        </div>
        <div className='widget Blog' id='Blog1'>
          <div className='blog_posts hfeed uk-child-width-1-1 uk-child-width-1-2@m uk-child-width-1-3@l'
               data-uk-grid='masonry:true' id='blog_posts'>
            <CheatsheetsListAll />
          </div>
        </div>
      </div>
    </Layout>
)};

export default CheatsheetsIndexPage

export const cheatsheetPageQuery = graphql`
  query CheatcheetsQuery {
    site {
      siteMetadata {
        title
        host
      }
    }
  }
`;
